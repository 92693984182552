import { FC, ReactNode } from "react";

import { Navigate, useParams } from "react-router-dom";

import { useWorkspaceData } from "Shared/api/v2/hooks/workspaces";
import { withWorkspaceData } from "Shared/components/HOCs/withWorkspaceData";
import { GetUserWorkspaceData200 } from "Specs/v2/getUserWorkspaceData/200";
import { useCurrentOrgUuid } from "Utils/hooks/useCurrentOrg";
import { useQueryParams } from "Utils/uris";

export const workspaceBlockerNames = ["ifUia", "ifNotUia", "ifAdmin", "ifNotAdmin"] as const;
type WorkspaceBlockerName = (typeof workspaceBlockerNames)[number];

type WorkspaceReplacementProps = {
  params: Record<string, string>;
  queryParams: Record<string, string>;
  workspace: GetUserWorkspaceData200;
  currentOrgUuid: string;
};
type WorkspaceReplacement = (props: WorkspaceReplacementProps) => React.ReactElement | string;

type AltPath = string;

export type WorkspaceBlockerOptions = Partial<
  Record<WorkspaceBlockerName, WorkspaceReplacement | AltPath>
>;

type UIAProtectProps = {
  /* An (un-rendered) React component that will be rendered if the protections pass. Only Component OR element can be passed. */
  Component?: FC | React.ComponentType;
  /* A (pre-rendered) React element/node that will be rendered if the protections pass. Only element OR Component can be passed. */
  children?: ReactNode;
} & WorkspaceBlockerOptions;

/**
 * Given a replacement (string or component), will render the appropriate replacement/blocker
 * based on the current workspace, current user, and params.
 */
const UIABlocker = withWorkspaceData<{
  replacement: WorkspaceReplacement | AltPath;
}>(({ replacement, ...workspace }) => {
  const params = useParams();
  const queryParams = useQueryParams();

  const currentOrgUuid = useCurrentOrgUuid();

  if (typeof replacement === "string") return <Navigate to={replacement} />;
  const replacementElement = replacement({ params, queryParams, workspace, currentOrgUuid });
  if (typeof replacementElement === "string") return <Navigate to={replacementElement} />;
  return replacementElement;
});

/** Maps the name of a blocking prop to the circumstances under which it should block. */
const workspaceBlockingRules: Record<
  WorkspaceBlockerName,
  (workspace: GetUserWorkspaceData200) => boolean
> = {
  ifUia: workspace => workspace.user.canViewUnifiedIA,
  ifNotUia: workspace => !workspace.user.canViewUnifiedIA,
  ifAdmin: workspace => workspace.user.isUnchainedAdmin,
  ifNotAdmin: workspace => !workspace.user.isUnchainedAdmin,
};

/**
 * Given a set of UIAProtect props, will return a blocker if the props indicate that a blocker should be shown.
 */
export const useGetWorkspaceBlocker = (props: Omit<UIAProtectProps, "Component" | "children">) => {
  const workspaceQuery = useWorkspaceData();
  const workspace = workspaceQuery.data;

  const blockersToTest = Object.keys(workspaceBlockingRules).filter(name => !!props[name]);
  const blockerName = blockersToTest.find(name => workspaceBlockingRules[name](workspace));
  const blocker = blockerName ? props[blockerName] : null;
  if (blocker) {
    return <UIABlocker replacement={blocker} />;
  }
};

/** A more lightweight/faster protect component that just requires workspace data */
export const UIAProtect = withWorkspaceData<UIAProtectProps>(props => {
  const workspaceBlocker = useGetWorkspaceBlocker(props);

  if (workspaceBlocker) return workspaceBlocker;

  const { Component, children } = props;

  return Component ? <Component /> : <>{children}</>;
});
