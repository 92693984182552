import React, { useCallback, useEffect, useMemo, useState } from "react";

import { LoadingModal } from ".";
import { LeadingCheckbox, ModalContent, ModalFooter } from "@unchained/component-library";
import Big from "big.js";
import cx from "classnames";
import { capitalize } from "lodash";
import { useSelector } from "react-redux";

import { Link } from "Components/Link";
import {
  setModalStatus,
  STREAMING_QUOTE_OPEN,
  updateAmount,
  updateAmountCurrency,
  USD,
  useBuyBitcoinDispatch,
  useBuyBitcoinStore,
  useSocket,
  WEB_SOCKET_FAILURE_MODAL_STATUS,
} from "Contexts/BuyBitcoin";
import { getCurrentOrg } from "Redux/selectors/spendingSelectors";
import { AppModalManager } from "Shared/components/Modals";
import { usePrevious } from "Utils/hooks";
import { formatCurrency } from "Utils/strings";

import { PreviewContentItem, TradingFeesTooltip } from "../components";
import { TradeLimitExceededModal } from "../components/TradeLimitExceededWarning";
import { useAmountPaidFromCashBalance, useIsPaidViaCashBalanceOnly } from "../hooks";
import styles from "./PreviewOrderModal.module.scss";
import { TradeModalContainer } from "./TradeModalContainer";

interface PreviewOrderModalProps {
  next: () => void;
  back: () => void;
}

/**
 * Component that fetches a streaming quote and on receive of that quote displays a user with
 * a constantly updating streaming quote bitcoin price, and allows them to purchase btc.
 * @param {Function} next - Function bringing a user to the next modal in a series of buy bitcoin modals.
 * @param {Function} back - Function bringing a user to the previous modal in a series of buy bitcoin modals.
 * @returns {React.Component}
 */
const PreviewOrderModal = ({ next, back }: PreviewOrderModalProps) => {
  const {
    totalCost,
    bitcoinPrice,
    destination,
    amountCurrency,
    isStreamingQuoteResponseLoading,
    amount,
    streamingQuoteID,
    streamingQuoteVersion,
    streamingQuoteStatus,
    feeAmountUSD,
    purchaseAmountUSD,
    purchaseAmountBTC,
    fees,
    wireAmountDue,
    remainingCash,
    maximumPurchaseAmount,
    credit,
  } = useBuyBitcoinStore();
  const isCreditEnabled = credit.isEnabled;

  const dispatch = useBuyBitcoinDispatch();

  const { getStreamingQuote, acceptTradeRequest } = useSocket();

  const currentOrg = useSelector(getCurrentOrg);
  const isIraOrg = currentOrg?.account_type === "ira";

  const streamingQuoteUniqueId = `${streamingQuoteID}${streamingQuoteVersion}`;
  const previousStreamingQuote: string = usePrevious(streamingQuoteID);
  const [isFirstStreamingQuote, setIsFirstStreamingQuote] = useState(false);
  const [areTermsAndConditionsAccepted, setAreTermsAndConditionsAccepted] = useState(false);

  /**
   * Set isFirstStreamingQuote to true when the user
   * receives their first streaming quote. If the user receives
   * another streaming quote, set isFirstStreamingQuote to false.
   */
  useEffect(() => {
    if (!!previousStreamingQuote) {
      setIsFirstStreamingQuote(false);
    } else {
      setIsFirstStreamingQuote(true);
    }
  }, [streamingQuoteUniqueId, previousStreamingQuote]);

  useEffect(() => {
    getStreamingQuote(amount, amountCurrency);
  }, [getStreamingQuote, amount, amountCurrency]);

  const showOrderSummaryModal = () => {
    acceptTradeRequest();
    next();
  };

  const isTotalAmountKeyAnimateOnChange = amountCurrency === USD ? false : true;

  /**
   * If a streaming quote has not arrived after 30 seconds we can assume
   * something has gone wrong, and set the modal status to reflect the network error.
   */
  const streamingQuoteResponseLoadingTimeout = useCallback(() => {
    return setTimeout(() => {
      dispatch(setModalStatus(WEB_SOCKET_FAILURE_MODAL_STATUS));
    }, 30000);
  }, [dispatch]);

  const feeCostFormatted = feeAmountUSD ? `$${formatCurrency(feeAmountUSD)}` : "---";

  const isPaidViaCashBalanceOnly = useIsPaidViaCashBalanceOnly(wireAmountDue);

  const amountPaidFromCashBalance = useAmountPaidFromCashBalance(
    totalCost,
    wireAmountDue,
    isPaidViaCashBalanceOnly
  );

  const hasTotalCostExceededMaxPurchase = useMemo(() => {
    try {
      return Big(totalCost).gt(maximumPurchaseAmount.USD);
    } catch (e) {
      return false;
    }
  }, [totalCost, maximumPurchaseAmount]);

  const remainingCashFormatted = useMemo(() => {
    try {
      if (hasTotalCostExceededMaxPurchase && !isCreditEnabled) {
        // If a cash only user would exceeded their cash balance with this
        // purchase, use the wireAmountDue to show the hypothetically negative
        // cash balance they would have.
        // This purchase would not go through though since we are blocking cash
        // only users from making a purxhase if hasTotalCostExceededMaxPurchase is true.
        return `-$${formatCurrency(wireAmountDue)}`;
      } else {
        return `$${formatCurrency(remainingCash)}`;
      }
    } catch (e) {
      return `$${formatCurrency(remainingCash)}`;
    }
  }, [remainingCash, hasTotalCostExceededMaxPurchase, isCreditEnabled, wireAmountDue]);

  const setStreamingQuoteToMaxUsdAmount = () => {
    dispatch(updateAmount(maximumPurchaseAmount.USD));
    dispatch(updateAmountCurrency(USD));
  };

  return Boolean(streamingQuoteStatus === STREAMING_QUOTE_OPEN && !!totalCost) ? (
    <TradeModalContainer title={"Preview buy"} showBackArrow={true} onBack={back}>
      <h2
        className={cx(styles.btcHeader, {
          [styles.btcHeader__flashPrimary]:
            !isTotalAmountKeyAnimateOnChange && !isFirstStreamingQuote,
        })}
        key={`${streamingQuoteUniqueId}-BTCPurchaseAmountkey`}
      >{`${purchaseAmountBTC} BTC`}</h2>
      <p className={styles.destination}>
        To: {capitalize(destination.type)} {destination.id}
      </p>
      <ModalContent>
        <div data-testid="previewOrderModalContentContainer" className={styles.contentContainer}>
          <PreviewContentItem
            key={`${streamingQuoteUniqueId}-bitcoinPricekey`}
            animateOnChange={!isFirstStreamingQuote}
            title="BTC price"
            content={`$${formatCurrency(bitcoinPrice)}`}
          />
          <PreviewContentItem
            key={`${streamingQuoteUniqueId}-purchaseAmountUSDKey`}
            animateOnChange={isTotalAmountKeyAnimateOnChange && !isFirstStreamingQuote}
            title={`Amount (USD)`}
            content={`$${formatCurrency(purchaseAmountUSD)}`}
          />
          <PreviewContentItem
            key={`${streamingQuoteUniqueId}-FeeKey`}
            animateOnChange={isTotalAmountKeyAnimateOnChange && !isFirstStreamingQuote}
            title={[
              "Unchained fee",
              <TradingFeesTooltip
                infoIconClass={styles.infoIcon}
                key="tradingFeesToolTip"
                fees={fees}
              />,
            ]}
            content={feeCostFormatted}
          />
          <PreviewContentItem
            key={`${streamingQuoteUniqueId}-wireAmountDue`}
            animateOnChange={isTotalAmountKeyAnimateOnChange && !isFirstStreamingQuote}
            title={"Total cost"}
            content={`$${formatCurrency(totalCost)}`}
            contentStyles={cx(styles.totalCost, {
              "!text-red-600": hasTotalCostExceededMaxPurchase && !isCreditEnabled,
            })}
            titleStyles={styles.totalCost}
          />
        </div>
        <div className={`${styles.contentContainer} mt-4`}>
          <>
            <PreviewContentItem
              key={`${streamingQuoteUniqueId}-paymentFromCash`}
              animateOnChange={isTotalAmountKeyAnimateOnChange && !isFirstStreamingQuote}
              title={"Payment from cash"}
              content={`$${formatCurrency(amountPaidFromCashBalance)}`}
            />

            <PreviewContentItem
              key={`${streamingQuoteUniqueId}-remainingCash`}
              animateOnChange={isTotalAmountKeyAnimateOnChange && !isFirstStreamingQuote}
              title={"Remaining cash"}
              content={remainingCashFormatted}
            />
          </>

          {!isPaidViaCashBalanceOnly && isCreditEnabled && (
            <PreviewContentItem
              key={`${streamingQuoteUniqueId}-wireAmountDue`}
              animateOnChange={isTotalAmountKeyAnimateOnChange && !isFirstStreamingQuote}
              title={"Wire amount due"}
              content={`$${formatCurrency(wireAmountDue)}`}
              contentStyles={styles.totalCost}
              titleStyles={styles.totalCost}
            />
          )}
        </div>

        {hasTotalCostExceededMaxPurchase && !isCreditEnabled ? (
          <TradeLimitExceededModal
            onAdjustValues={setStreamingQuoteToMaxUsdAmount}
            onCancelTrade={AppModalManager.close}
          />
        ) : (
          <LeadingCheckbox
            containerClassName="mt-4 "
            checked={areTermsAndConditionsAccepted}
            onChange={() => setAreTermsAndConditionsAccepted(!areTermsAndConditionsAccepted)}
            name="Terms & Conditions"
            label={
              <p className="!mb-0 ml-1 mr-3 text-xs text-gray-800">
                {isIraOrg
                  ? "I consent to the transaction above and direct the IRA custodian to take action accordingly. "
                  : null}
                I agree that this transaction will be governed by
                <Link to="https://unchained.com/terms-of-service">
                  {" "}
                  Unchained's Terms of Service
                </Link>
                .{" "}
                {isIraOrg
                  ? null
                  : "I understand that Unchained only accepts wire payments for bitcoin purchases."}
                {destination.type === "loan"
                  ? ` I further acknowledge that Unchained Trading will contribute the proceeds of this trade to the appropriate vault in order to increase the value of the collateral pledged for Loan ID ${destination.id}.`
                  : null}
              </p>
            }
          />
        )}
      </ModalContent>
      <ModalFooter
        className="!mt-7"
        actions={[
          {
            disabled:
              (isStreamingQuoteResponseLoading && !isFirstStreamingQuote) ||
              !areTermsAndConditionsAccepted ||
              hasTotalCostExceededMaxPurchase,
            color: "primary",
            className: styles.button,
            onClick: showOrderSummaryModal,
            children: "Execute buy",
          },
        ]}
      ></ModalFooter>
    </TradeModalContainer>
  ) : (
    <LoadingModal height="450px" timeoutHandler={streamingQuoteResponseLoadingTimeout} />
  );
};

export { PreviewOrderModal };
