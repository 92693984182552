/** This file contains functions that help find particular data within bitcoin transactions and the transfers
 * they contain.
 */
import Big from "big.js";
import get from "lodash/get";

import {
  AccountTransfer,
  GetProductBtcTransactions200,
  ProductBtcTransaction,
} from "Specs/v1/getProductBtcTransactions/200";
import { btcOperationTypes as types } from "Utils/enums";

// Transaction Lists

/**
 * Returns a list of Transfers from a list of Transactions.
 */
export function getTransferInfoListFromTransactions(
  transactions: ProductBtcTransaction[],
  contextProductUuid: string
) {
  let transfers = [];
  for (const transaction of transactions) {
    transfers = transfers.concat(getTransferInfoList(transaction, contextProductUuid));
  }
  return transfers;
}

// Transactions

/**
 * Returns a list of info about every relevant Transfer (see transferIsRelevant) in the BtcTransaction.
 * The returned object will contain the properties:
 * - transfer: The Transfer object.
 * - transaction: The Transaction the Transfer was found in.
 * @param {Object} transaction One of the btc_transactions returned by API.GetBtcTransactions.
 * @param {string|undefined} contextProductUuid The UUID of the product we want to get the relevant transfer for.
 *                                              If undefined, all transfers that have visible products will
 *                                              be included.
 * @param {boolean} skipFeeFilter Defaults false. If true, will skip the
 * combining of fee amounts into the source amount.
 */
export function getTransferInfoList(
  transaction: ProductBtcTransaction,
  contextProductUuid: string,
  skipFeeFilter = false
) {
  let relevantTransfers = transaction.transfers.filter(transfer =>
    transferIsRelevant(transfer, contextProductUuid)
  );
  if (!skipFeeFilter) {
    relevantTransfers = combineOrFilterFeeTransfers(relevantTransfers, contextProductUuid);
  }
  return relevantTransfers.map(transfer => {
    return { transfer, transaction };
  });
}

/**
 * Combines fee account transfers into the primary transfer for simple transfers. This is kind of an ugly kludge to
 * prevent every transaction from showing an extra fee line in transaction history lists. We should probably do
 * something like always display the amount sent/receive not including fees, and then have something like a
 * running balance that includes fees. However, we probably need to think about more complicated cases where
 * multiple accounts are sending to fees.
 * @param transfers {Object[]}
 * @param contextProductUuid {string}
 */
export function combineOrFilterFeeTransfers(transfers, contextProductUuid) {
  if (
    transfers.length === 2 &&
    isSimpleTransfer(transfers[0], contextProductUuid) &&
    transfers[1].targets.length === 0 && // Second transfer is fees.
    transfers[1].sources.length === 1
  ) {
    const [baseTransfer, feeTransfer] = transfers;
    const feeSource = feeTransfer.sources[0];
    const feeAmount = feeSource.amount;
    const { source, target } = getSimpleTransferLocations(baseTransfer, contextProductUuid);
    const sourceProduct = getLocationProduct(source);
    const targetProduct = getLocationProduct(target);
    const feeSourceProduct = getLocationProduct(feeSource);

    const isFeeOnSendingProduct =
      sourceProduct &&
      feeSourceProduct &&
      sourceProduct.uuid === feeSourceProduct.uuid &&
      (!targetProduct || sourceProduct.uuid === contextProductUuid);
    const isFeeOnReceivingProduct =
      targetProduct &&
      feeSourceProduct &&
      targetProduct.uuid === feeSourceProduct.uuid &&
      targetProduct.uuid === contextProductUuid;

    if (isFeeOnSendingProduct) {
      // This case is for a fee associated with the sending product (source),
      // where the amount of the baseTransfer does not include the fee, but
      // adding in the fee is the correct way to show the transfer out of the product.
      // The resulting amount will be the actual transfer plus the  fee, and the fee
      // transfer will be dropped
      // NOTE: This modifies the data from the input... not ideal, likely to
      // result in an error somewhere in the future
      source.amount = Big(source.amount).plus(feeAmount);
      return [baseTransfer];
    } else if (isFeeOnReceivingProduct) {
      // This case is for a fee associated with the receiving product (target),
      // where the amount of the baseTransfer includes the fee, but the displayed
      // amount of the transaction should not include the fee.
      // The resulting amount will be the actual transfer minus the fee, and the fee
      // transfer will be dropped
      // NOTE: This modifies the data from the input... not ideal, likely to
      // result in an error somewhere in the future
      target.amount = Big(target.amount).minus(feeAmount);
      return [baseTransfer];
    }
  }
  // else: all other fee transfers will filtered out,
  // with the transfer amount unmodified
  return transfers.filter(transfer => {
    return transfer.targets.length !== 0;
  });
}

/**
 * Returns true if the passed `output` is to an address owned by the passed product.
 */
export function isOwnOutput(product, output) {
  return (
    output.product?.product_type === product.product_type && output.product?.uuid === product.uuid
  );
}

/**
 * DEPRECATED, use getTransferInfoList instead.
 * Returns the first transfer found in the transaction that involves the current product.
 * Note that by only choosing a single transfer, this does not support multi destination transfers. This
 * code will need to be upgraded when we want to support that.
 * @param {Object} transaction One of the btc_transctions returned by API.GetBtcTransactions.
 * @param {string} contextProductUuid The UUID of the product we want to get the relevant transfer for.
 * This is exported for testing.
 */
export function getFirstRelevantTransfer(
  transaction: GetProductBtcTransactions200["btc_transactions"][number],
  contextProductUuid: string
) {
  for (const transfer of transaction.transfers) {
    if (transferIsRelevant(transfer, contextProductUuid)) {
      return transfer;
    }
  }
}

// Transfers

/**
 * This returns true if the transfer is "simple".
 * If no contextProductUuid is passed, "simple" means:
 * - one source, and one visible target, or
 * - one visible source, and one target.
 * If a contextProductUuid is passed, "simple" means:
 * - one source, and one target associated with contextProductUuid, or
 * - one source associated with the contextProductUuid, and one target.
 * @param transfer {Object}
 * @param contextProductUuid {string}
 */
export function isSimpleTransfer(transfer, contextProductUuid) {
  if (contextProductUuid === undefined) {
    return _getSimpleTransferLocations(transfer) !== undefined;
  } else {
    return _getSimpleTransferLocationsForProduct(transfer, contextProductUuid) !== undefined;
  }
}

/**
 * Gets an object with the relevant Transfer locations.
 * - If contextProductUuid is passed, the Transfer locations will be the ones relevant to the product with
 *   the passed uuid.
 * - If not, the Transfer locations will be the ones relevant to the user.
 * The returned object the properties:
 * - source: The simple Transfer Source.
 * - target: The simple Transfer Target.
 * @param transfer {Object} The simple transfer. Note that there are different requirements for a simple
 *                          transfer in the context of a product than for one without that context.
 * @param contextProductUuid {string}
 */
export function getSimpleTransferLocations(transfer, contextProductUuid) {
  let transferLocations, errorMessage;
  if (contextProductUuid === undefined) {
    transferLocations = _getSimpleTransferLocations(transfer);
    errorMessage = "Transfer is not simple";
  } else {
    transferLocations = _getSimpleTransferLocationsForProduct(transfer, contextProductUuid);
    errorMessage = "Transfer is not simple for the passed product";
  }
  if (!transferLocations) {
    throw new Error(errorMessage);
  }
  return transferLocations;
}

/**
 * Returns the balance change for a particular product as a result of the transfer.
 * @param {Object} transfer A transfer of the form inside the btc_transctions returned by API.GetBtcTransactions.
 * @param {string} contextProductUuid The UUID of the vault we want to get the balance change for.
 * @Return {Big}
 */
export function getBalanceChangeForProduct(transfer, contextProductUuid) {
  const productSource = getProductLocation(transfer.sources, contextProductUuid);
  const productTarget = getProductLocation(transfer.targets, contextProductUuid);
  const amountSent = get(productSource, "amount") || 0;
  const amountReceived = get(productTarget, "amount") || 0;
  // Ideally this would be done on being received from the server).
  return Big(amountReceived).minus(amountSent);
}

/**
 * Returns the aggregate balance change for all visible products.
 * @param {Object} transfer A transfer of the form inside the btc_transctions returned by API.GetBtcTransactions.
 * @Return {Big}
 */
export function getBalanceChangeForUser(transfer) {
  // Ideally conversions to BigNumbers would be done on being received from the server rather than here.
  const productSources = getLocationsWithVisibleProduct(transfer.sources);
  const productTargets = getLocationsWithVisibleProduct(transfer.targets);
  const amountSent = productSources.reduce((sum, source) => sum.plus(source.amount), Big(0));
  const amountReceived = productTargets.reduce((sum, target) => sum.plus(target.amount), Big(0));
  return Big(amountReceived).minus(amountSent);
}

// Simple Transfers - These methods all take an object like {source, target} that represents a simple transfer.

/**
 * Returns info about the other product that isn't the one associated with the passed uuid.
 * Returns an object with the following properties:
 * - location: The Transfer Source or Target for the other product.
 * - product: The other product.
 * @param {source, target} The simple transfer locations.
 * @param productUuid The product in context. If undefined, the source product is returned if it exists.
 */
export function getOtherLocationInfo({ source, target }, productUuid) {
  const sourceProduct = getLocationProduct(source);
  const targetProduct = getLocationProduct(target);
  if (get(targetProduct, "uuid") === productUuid || (productUuid === undefined && !sourceProduct)) {
    return { otherLocation: source, otherProduct: sourceProduct };
  } else {
    return { otherLocation: target, otherProduct: targetProduct };
  }
}

/**
 * Gets the display name of the passed operationType for ledger entries.
 * @param {Object} simpleTransferLocations An object {source, target} representing a simple transfer.
 * @param {btcOperationTypes|undefined} operationType
 * @param {contextProductUuid} The uuid of the product in context.
 * @param {Object} options Has the properties:
 *                         - receive - (Default: 'Deposit') String to return for a default receive.
 *                         - send - (Default: 'Withdrawal') String to return for a default send.
 *                         - loanRolloverDisplayType: (Default: 0)
 *                           - 0 means to display "Loan <uuid> closed",
 *                           - 1 means display "Loan rollover".
 */
export function getOperationName(
  { source, target },
  operationType,
  contextProductUuid,
  options = {} as Record<string, unknown>
) {
  const sourceProduct = getLocationProduct(source);
  const targetProduct = getLocationProduct(target);
  let contextProduct;
  if (sourceProduct && sourceProduct.uuid === contextProductUuid) {
    contextProduct = sourceProduct;
  } else if (targetProduct && targetProduct.uuid === contextProductUuid) {
    contextProduct = targetProduct;
  }

  // Sending or receiving from one of your own vaults/loans or an admin who can view both products.
  if (sourceProduct) {
    if (operationType === types.vault_sweep) {
      return "Sweep";
    } else if (operationType === types.loan_sweep) {
      return "Sweep";
    } else if (operationType === types.loan_redemption) {
      return "Redemption";
    } else if (operationType === types.loan_liquidation) {
      return "Liquidation";
    } else if (operationType === types.loan_closing) {
      if (contextProduct === sourceProduct || !targetProduct) {
        return "Loan closed";
      } else {
        if (options.loanRolloverDisplayType === 1) {
          return "Loan rollover";
        } else {
          return `Loan ${sourceProduct.uuid} closed`;
        }
      }
    } else if (operationType === types.vault_sale_transaction) {
      return "Sale";
    } else if (targetProduct) {
      return "Transfer";
    }
  } else if (!targetProduct) {
    throw new Error("No relevant product found.");
  } else if (operationType === types.client_buy_bitcoin) {
    return "Purchase";
  } else if (operationType === types.batch_settlement) {
    return "Settlement";
  }
  // else
  return targetProduct ? options.receive || "Deposit" : options.send || "Withdrawal";
}

// Locations (Transfer Sources and Targets)

/**
 * Gets the loan or vault object for the passed Source or Target. Returns undefined if there is no product account.
 * @param location A Transfer Source or Target.
 */
export function getLocationProduct(location) {
  if (location.account) {
    const productType = location.account.loan ? "loan" : "vault";
    return location.account[productType];
  }
}

// Products

/**
 * Returns the bare name of the product.
 * @param {Object} product The loan or vault object.
 * @param {string} prefix If true, the type will be prefixed onto the name.
 */
export function getProductName(product, prefix = false) {
  if (prefix) {
    return product.product_type + " " + getProductName(product);
  }
  // else
  if (product.product_type === "loan") {
    return product.uuid;
  } else {
    return product.name;
  }
}

// Outputs

/**
 * Returns the change output from a transactions output list.
 * @param transactionOutputs This must be a transaction that has a `transactionOutputs` property.
 * @param spendingProduct An object representing the product the transaction is spending from.
 */
export function findChangeOutput(transactionOutputs, spendingProduct) {
  return transactionOutputs.find(
    ({ product }) =>
      product.product_type === spendingProduct.product_type && product.uuid === spendingProduct.uuid
  );
}

// Private

/**
 * Returns true if the passed transfer represents a transfer to or from an account relevant to the user.
 * "Relevant to" is either any visible account or a specific context account (depending on whether
 * contextProductUuid is passed in).
 * @param transfer An account Transfer.
 * @param {string||undefined} contextProductUuid The UUID of the product we want to check if this transfer is
 *                            relevant for. If defined, a transfer will only be relevant if it involves the
 *                            context product with the passed `contextProductUuid`.
 */
function transferIsRelevant(transfer: AccountTransfer, contextProductUuid: string) {
  const locations = transfer.sources.concat(transfer.targets);
  for (const location of locations) {
    // Ignore 0 value transfer locations.
    if (+location.amount !== 0) {
      const locationProduct = getLocationProduct(location);
      const locationProductUuid = get(locationProduct, ["uuid"]);
      if (contextProductUuid !== undefined) {
        if (contextProductUuid === locationProductUuid) {
          return true;
        }
      } else if (Boolean(locationProduct)) {
        // The product is visible.
        return true;
      }
    }
  }
  return false;
}

/**
 * Returns the one location associated with the product with the passed contextProductUuid, or undefined if
 * one doesn't exist.
 * @param locations A list of Transfer Sources or Targets.
 * @param contextProductUuid
 * @returns {Object|undefined}
 */
function getProductLocation(locations, contextProductUuid) {
  for (const location of locations) {
    const product = getLocationProduct(location);
    if (get(product, "uuid") === contextProductUuid) {
      return location;
    }
  }
}

/** @param locations A list of Transfer Sources or Targets. */
function getLocationsWithVisibleProduct(locations) {
  return locations.filter(location => getLocationProduct(location));
}

/**
 * Returns either the value getSimpleTransferLocations should return, or undefined if the transfer
 * isn't simple.
 */
function _getSimpleTransferLocations(transfer) {
  const visibleTargets = getLocationsWithVisibleProduct(transfer.targets);
  if (transfer.sources.length === 1 && visibleTargets.length === 1) {
    return { source: transfer.sources[0], target: visibleTargets[0] };
  }
  const visibleSources = getLocationsWithVisibleProduct(transfer.sources);
  if (transfer.targets.length === 1 && visibleSources.length === 1) {
    return { source: visibleSources[0], target: transfer.targets[0] };
  }
}

/**
 * Returns either the value getSimpleTransferLocations should return, or undefined if the transfer
 * isn't simple for the product with the passed uuid.
 */
function _getSimpleTransferLocationsForProduct(transfer, contextProductUuid) {
  const productTarget = getProductLocation(transfer.targets, contextProductUuid);
  if (transfer.sources.length === 1 && productTarget) {
    return { source: transfer.sources[0], target: productTarget };
  }
  const productSource = getProductLocation(transfer.sources, contextProductUuid);
  if (transfer.targets.length === 1 && productSource) {
    return { source: productSource, target: transfer.targets[0] };
  }
}
