import { useContext } from "react";

import { Banner, Button } from "@unchained/component-library";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { useNavigate } from "Components/Link";
import { LoadingContext } from "Contexts/LoadingContext";
import { ManifestStep } from "Routes/onboard/(shared)";
import { orgQueryKeys } from "Shared/api";
import {
  ChangeProposalAPI,
  ChangeProposalGroup,
  ChangeProposalType,
  LiveVote,
  OrgMemberChangeProposal,
} from "Shared/api/changeProposalApi";
import { AccountUser } from "Specs/v1/getAccount/200";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { useEasyToasts } from "Utils/toasts";

import { PendingChanges } from "./PendingChanges";
import { VotesTable } from "./VotesTable";

export default function ChangeProposalStep({
  group,
  org,
  currentUser,
  exitLink,
}: {
  group: ChangeProposalGroup;
  org: CompleteOrg;
  currentUser: AccountUser;
  exitLink: string;
}) {
  const { uuid } = useParams();
  const queryClient = useQueryClient();
  const context = useContext(LoadingContext);
  const { showApiSuccessToast, showErrorToast } = useEasyToasts();
  const { quorum_config } = org;
  const min_approvals = quorum_config?.min_approvals;
  const navigate = useNavigate();

  const done = () => navigate(exitLink);

  const existingVoteForUser = group.liveVotes.find(
    (vote: LiveVote) => vote.user.uuid === currentUser.uuid
  );

  const currentUserIsRequester = group.requester.uuid === currentUser.uuid;
  const requester = currentUserIsRequester
    ? "You"
    : group.requester?.email || "User no longer exists";

  const revoked = group.state === "canceled";
  const executed = group.state === "executed";
  const denied = group.state === "denied";
  const pending = group.state === "pending";
  const canVote = org.allowed_actions.includes("vote_change_proposal_group");

  const approvalWillRemoveCurrentUserFromOrg = () => {
    return (
      group.changeProposals.some(
        changeProposal =>
          changeProposal.type === ChangeProposalType.OrgMemberRemoveProposal &&
          (changeProposal as OrgMemberChangeProposal).email === currentUser.email
      ) && group.liveVotes.filter(v => v.vote === "approve").length === min_approvals - 1
    );
  };

  const changeProposalMutation = useMutation((type: "approve" | "reject") => {
    switch (type) {
      case "approve":
        return ChangeProposalAPI.ApproveChangeProposalGroup(org.uuid, group.uuid);
      case "reject":
        return ChangeProposalAPI.RejectChangeProposalGroup(org.uuid, group.uuid);
      default:
        throw new Error("Invalid type");
    }
  });

  const sendMutation = (type: "approve" | "reject", successMessage: string) => {
    context.loading.set(true);

    // redirect to home if the CPG will execute and the current user will be removed from org on approving vote
    const redirectHome = type === "approve" && approvalWillRemoveCurrentUserFromOrg();

    changeProposalMutation.mutate(type, {
      onSuccess: async () => {
        showApiSuccessToast({ message: successMessage });
        if (redirectHome) {
          navigate("/home");
        } else {
          queryClient.invalidateQueries(orgQueryKeys.changeProposals(uuid));
          queryClient.invalidateQueries(orgQueryKeys.show(uuid));
          queryClient.refetchQueries(orgQueryKeys.changeProposals(uuid));
          queryClient.refetchQueries(orgQueryKeys.show(uuid));
        }
      },
      onError: err => showErrorToast(err),
      onSettled: () => context.loading.set(false),
    });
  };

  const approve = () => sendMutation("approve", "Change proposal approved");
  const reject = () => sendMutation("reject", "Change proposal rejected");

  return (
    <ManifestStep
      width="max-w-[800px]"
      title="Approval requested"
      description={
        <div>
          <span className="font-semi">{requester}</span>
          {currentUserIsRequester ? " are " : " is "}
          requesting the following changes to <span className="font-semi">{org.name}</span>
        </div>
      }
    >
      <div className="">
        {group.changeProposals.map(changeProposal => (
          <PendingChanges key={changeProposal.uuid} changeProposal={changeProposal} org={org} />
        ))}
      </div>

      {pending || group.liveVotes.length ? (
        <VotesTable
          liveVotes={group.liveVotes}
          minApprovals={min_approvals}
          showEmptyRows={pending}
        />
      ) : null}

      {revoked && (
        <Banner type="warning" title="Change proposal revoked" className="mb-4">
          <div>This change proposal was canceled by {group.requester.email}</div>
        </Banner>
      )}

      {denied && (
        <Banner type="warning" title="Change proposal denied" className="mb-4">
          <div>This change proposal did not recieve enough approving votes.</div>
        </Banner>
      )}

      {executed && (
        <Banner type="success" title="Change proposal executed" className="mb-4">
          <div>This change proposal was executed</div>
        </Banner>
      )}

      {pending && (
        <>
          <div className="flex justify-between rounded border border-gray-100 p-3 ">
            <span className="text-md font-bold">Approvals gathered</span>
            <span>
              <span className="font-bold">
                {group.liveVotes.filter(v => v.vote === "approve").length}
              </span>{" "}
              / {min_approvals}
            </span>
          </div>

          {canVote &&
            (existingVoteForUser ? (
              <div>
                <p className="mb-4">
                  You {existingVoteForUser.vote === "approve" ? "approved" : "denied"} this change
                  proposal.
                </p>
                <Button className="mb-4 w-full" type="button" color="primary" onClick={done}>
                  Continue
                </Button>
              </div>
            ) : (
              <div className="flex w-full gap-2">
                <Button className="grow" type="button" color="primary" onClick={approve}>
                  Approve
                </Button>
                <Button className="grow" type="button" color="tertiary" onClick={reject}>
                  Reject
                </Button>
              </div>
            ))}
        </>
      )}
    </ManifestStep>
  );
}
