import React from "react";

import { Navigate, Outlet, useLocation } from "react-router-dom";

import GlobalErrorBoundary from "Components/ErrorBoundaries/GlobalErrorBoundary";

import styles from "./PublicApp.module.scss";

const PublicApp = () => {
  const location = useLocation();

  // Auth pages need to break out of app layout
  const authPages = [
    /sign_up/,
    /login/,
    /credentials\/password\/reset/,
    /sign_ups\/(\w+)\/confirm/,
    /mfa\/resets/,
  ];
  const isAuthPage = authPages.some(pathRegex => pathRegex.test(location.pathname));

  if (isAuthPage) {
    return (
      <div id="main">
        <GlobalErrorBoundary userAuthenticated={false}>
          <div className={styles.fullPageContent}>
            <Outlet />
          </div>
        </GlobalErrorBoundary>
      </div>
    );
  }

  return <Navigate to="login" />;
};

export default PublicApp;
