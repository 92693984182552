/* eslint-disable-next-line no-restricted-imports */
import { Check } from "@mui/icons-material";
import { Banner, Button, ButtonProps, Chip } from "@unchained/component-library";
import cn from "classnames";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getAccountSpendingDataAction } from "Actions/transactionActions/spendingActions";
import { SpendingApproval } from "Reducers/transactionReducers/spendingReducer";
import { currentOrgSelector } from "Redux/selectors";
import { useCurrentUser, useMemoizedState } from "Redux/selectors/hooks";
import { spendingOperationSelector } from "Redux/selectors/spendingSelectors";
import { getFormattedVault } from "Redux/selectors/vaults/vaultShowSelectors";
import { numMembersWhoCanApprove, rolesForUserUuid } from "Routes/manage/utils";
import { BtcTxRequestApi } from "Shared/api/btcTxRequestApi";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { TransactionRequestAllowedActionsEnum } from "Specs/v1/getTransactionRequests/200";
import { useActions } from "Utils/hooks/useActions";
import { useErrorToast } from "Utils/toasts";

import styles from "./ApprovalStep.module.scss";
import wizStyles from "./SpendingWizard.module.scss";
import { TransactionDetails } from "./TransactionDetails";

const Approval = ({ name, index }: { name: string; index: number }) => (
  <div className={styles.approval}>
    <div>{index + 1}.</div>
    <div className={styles.approvalName}>{name}</div>
    <Chip type="success" label="Approved" icon={<Check />} />
  </div>
);

const EmptyApproval = ({ index }: { index: number }) => (
  <div className={cn(styles.approval, styles.approval_empty)}>
    <div>{index + 1}.</div>
    <div className={styles.emptyApprovalLine} />
  </div>
);

export const DelegateOrgApprovalStep = () => (
  <>
    <div className={wizStyles.card}>
      <div>
        <h2>Transaction approval in progress</h2>
        <p>Signing will be available once approval is complete.</p>
      </div>
    </div>

    <div className={wizStyles.transactionDetails}>
      <TransactionDetails />
    </div>
  </>
);

interface BaseApprovalStepProps {
  next: () => void;
  txRequest: ReturnType<
    typeof spendingOperationSelector
  >["operation"]["btc_transaction_requests"][number];
  org: CompleteOrg;
}

export const BaseApprovalStep = ({ next, txRequest, org }: BaseApprovalStepProps) => {
  const { getAccountSpendingData } = useActions({ getAccountSpendingDataAction });
  const currentUser = useCurrentUser();
  const location = useLocation();
  const cryptoAmount = useMemoizedState("transaction.outflow.cryptoAmount");
  const vault = useSelector(getFormattedVault) as {
    threshold: boolean;
    thresholdSpendAmount: number;
  };
  const showErrorToast = useErrorToast();

  // Path looks like (eg): /vaults/12345/liquidations/54321
  // And regex.exec stores the matches past index 0 (which is the whole path match)
  const reg = /(\w+)s\/(\w+)\/(\w+)s\/(\w+)/g;
  const [productType, productUuid, operationType, operationUuid] = reg
    .exec(location.pathname)
    .slice(1);
  const canApprove = txRequest.allowed_actions.includes(
    "approve_btc_transaction" as unknown as TransactionRequestAllowedActionsEnum
  );

  const onRequest = {
    onSuccess: () => {
      getAccountSpendingData(productType, productUuid, operationType, operationUuid);
    },
    onError: error => {
      showErrorToast(error);
    },
  };

  const approveRequest = useMutation(() => BtcTxRequestApi.Approve(txRequest.uuid), onRequest);
  const unapproveRequest = useMutation(() => BtcTxRequestApi.Unapprove(txRequest.uuid), onRequest);

  const exceedsThreshold =
    vault?.threshold &&
    vault.thresholdSpendAmount &&
    vault.thresholdSpendAmount < parseFloat(cryptoAmount);

  const quorumSize = org.quorum_config?.min_approvals;

  const approvals = (txRequest?.approvals || []).filter(app => app.approver);

  const currentUserApprovalIndex = approvals.findIndex(
    approval => approval.approver?.uuid === currentUser.uuid
  );
  const currentUserApproved = currentUserApprovalIndex !== -1;

  const firstApprovalByCreatedAt = approvals.sort(
    (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
  )[0];

  // Used as a proxy for who created the transaction
  const currentUserWasFirstToApprove =
    firstApprovalByCreatedAt?.approver?.uuid === currentUser.uuid;

  const complete = approvals.length >= quorumSize;

  let availableApprovers = 0;
  availableApprovers = numMembersWhoCanApprove(org);

  const actions: ButtonProps[] = [];

  if (approvals.length === quorumSize || !canApprove || currentUserWasFirstToApprove) {
    actions.push({
      color: "primary",
      children: "Next",
      onClick: next,
      disabled: !complete,
    });
  } else if (currentUserApproved) {
    actions.push({
      color: "destructive",
      children: "Revoke approval",
      onClick: () => unapproveRequest.mutate(),
    });
  } else {
    actions.push({
      color: "primary",
      children: "Approve",
      onClick: () => approveRequest.mutate(),
    });
  }

  const isAdmin = Boolean(
    rolesForUserUuid(currentUser.uuid, org.child_roles || []).find(role => role.name === "Admins")
  );

  if (isAdmin) {
    actions.push({
      variant: "text",
      children: "Review transaction approval settings ",
      to: "/manage",
    });
  }

  return (
    <>
      <div className={wizStyles.card}>
        <div>
          <h2>Transaction approval</h2>
          <p>
            Your organization requires at least {quorumSize} approvers to confirm a transaction -
            current state of approvals shown below.
          </p>
          {exceedsThreshold ? (
            <Banner type="tip" style={{ width: "100%" }}>
              This transaction exceeds the threshold set.
            </Banner>
          ) : null}
        </div>

        <div>
          <QuorumCountDisplay approvalsCount={approvals.length} quorumSize={quorumSize} />
          <h3 className="mb-4">approvals gathered</h3>
        </div>

        <ApprovalForm
          availableApprovers={availableApprovers}
          quorumSize={quorumSize}
          approvals={approvals as SpendingApproval[]}
          currentUserApprovalIndex={currentUserApprovalIndex}
          actions={actions}
        />
      </div>

      <div className={wizStyles.transactionDetails}>
        <TransactionDetails />
      </div>
    </>
  );
};

// There's some race condition immediately post-creation that occasionally causes this to render before the txRequest is there
// Think I fixed it elsewhere, but for extra security, bail until there's a txRequest
export const ApprovalStep = props => {
  const { operation } = useSelector(spendingOperationSelector);
  const org = useSelector(currentOrgSelector);
  const currentOrgIsDelegate = org.type === "delegate";

  const { btc_transaction_requests = [] } = operation;

  const txRequest = btc_transaction_requests[0];

  if (!txRequest) return null;

  if (currentOrgIsDelegate) {
    return <DelegateOrgApprovalStep />;
  }
  return <BaseApprovalStep {...props} txRequest={txRequest} org={org} />;
};

type ApprovalFormProps = {
  availableApprovers: number;
  quorumSize: number;
  approvals: SpendingApproval[];
  currentUserApprovalIndex: number;
  actions: ButtonProps[];
  className?: string;
};
export const ApprovalForm = ({
  availableApprovers,
  quorumSize,
  approvals,
  currentUserApprovalIndex,
  actions,
  className,
}: ApprovalFormProps) => {
  return (
    <>
      <div className={className}>
        <h5>Approvers ({availableApprovers} available)</h5>
        {new Array(quorumSize).fill(0).map((_, i) => {
          const approval = approvals[i];
          return approval ? (
            <Approval
              index={i}
              key={`approval-${approval.approver.name}`}
              name={
                i === currentUserApprovalIndex
                  ? `${approval.approver.name} (You)`
                  : approval.approver.name
              }
            />
          ) : (
            <EmptyApproval key={i} index={i} />
          );
        })}
      </div>

      <div className={styles.actions}>
        {actions.map(action => (
          <Button fullWidth key={action.children as string} {...action} />
        ))}
      </div>
    </>
  );
};

export const QuorumCountDisplay = ({ approvalsCount, quorumSize }) => {
  return (
    <h1 data-testid="quorumCountDisplay">
      {approvalsCount} <span className={styles.n}>/ {quorumSize}</span>
    </h1>
  );
};
