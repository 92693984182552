import { useMemo } from "react";

import { AmountDisplayWithTooltip, CurrentPriceDisplay } from ".";
import { Button } from "@unchained/component-library";
import Big from "big.js";
import { useSelector } from "react-redux";

import { FundIRAStepperWizard } from "Components/IRAs/FundIra/FundIraStepper";
import { Link } from "Components/Link";
import { CashDepositSlideout } from "Components/PaymentMethods/CashDepositSlideout";
import { AmountDisplay } from "Components/TradingDesk/components/AmountDisplay";
import { ONLINE_STATUS, useBuyBitcoinStore } from "Contexts/BuyBitcoin";
import { getCurrentOrg } from "Redux/selectors/spendingSelectors";
import { AppModalManager } from "Shared/components/Modals";
import { AppSlideoutManager } from "Shared/components/SlideoutManager";
import { formatCurrency } from "Utils/strings";

import { AvailableCreditTooltip } from "../modals/BuyBitcoinModal/AvailableCreditTooltip";
import styles from "../modals/BuyBitcoinModal/BuyBitcoinModal.module.scss";

export const BuyPositionSummary = () => {
  const {
    bitcoinPrice,
    credit,
    cash: { cashBalanceUsd, availableBalanceUsd },
    maximumPurchaseAmount,
    status,
  } = useBuyBitcoinStore();
  const isCreditEnabled = credit.isEnabled;

  const currentOrg = useSelector(getCurrentOrg);
  const isIraOrg = currentOrg?.account_type === "ira";

  const openCashDepositSlideout = () => {
    AppSlideoutManager.open(() => <CashDepositSlideout />);
  };

  const pendingCashDeposits = useMemo(() => {
    try {
      return formatCurrency(Big(cashBalanceUsd).minus(availableBalanceUsd).toString());
    } catch (e) {
      return "N/A";
    }
  }, [cashBalanceUsd, availableBalanceUsd]);

  const cashBalanceLabel = isIraOrg ? "IRA cash balance" : "Cash balance";

  const cashBalanceTooltipText = isIraOrg ? (
    <>
      Submit an{" "}
      <Link type="text" onClick={() => AppModalManager.open(() => <FundIRAStepperWizard />)}>
        {" "}
        IRA funding form request{" "}
      </Link>
      to increase your IRA cash balance.
    </>
  ) : (
    `Your cash balance includes $${pendingCashDeposits} in pending deposits. Any purchase above $${formatCurrency(
      availableBalanceUsd
    )} may impact how quickly you receive your bitcoin.`
  );

  const isCashBalanceAboveMaxPurchaseAmount = useMemo(() => {
    try {
      return Big(cashBalanceUsd).gt(maximumPurchaseAmount.USD);
    } catch (e) {
      return false;
    }
  }, [cashBalanceUsd, maximumPurchaseAmount.USD]);

  const isCreditAndCashAboveMaxPurchase = useMemo(() => {
    try {
      return Big(cashBalanceUsd).plus(credit.availableUsd).gt(maximumPurchaseAmount.USD);
    } catch (e) {
      return false;
    }
  }, [cashBalanceUsd, maximumPurchaseAmount.USD, credit.availableUsd]);

  const isAvailableCashBalanceAboveMaxPurchaseAmount = useMemo(() => {
    try {
      return Big(availableBalanceUsd).gt(maximumPurchaseAmount.USD);
    } catch (e) {
      return false;
    }
  }, [availableBalanceUsd, maximumPurchaseAmount.USD]);

  const isAvailableCashBalanceAndCashBalanceEqual = cashBalanceUsd === availableBalanceUsd;

  const CashBalanceDisplay = () =>
    (isAvailableCashBalanceAndCashBalanceEqual || isAvailableCashBalanceAboveMaxPurchaseAmount) &&
    !isIraOrg ? (
      <AmountDisplay amount={cashBalanceUsd} label={cashBalanceLabel} />
    ) : (
      <AmountDisplayWithTooltip
        amount={cashBalanceUsd}
        label={cashBalanceLabel}
        tooltipContent={cashBalanceTooltipText}
      />
    );

  const MaxPurchaseDisplay = () => {
    const label = "Maximum purchase";
    const balanceExceedsMaxPurchaseToolTip = `You may purchase up to $${formatCurrency(
      maximumPurchaseAmount.USD
    )} in a single trade.`;

    const isTradingOnline = status === ONLINE_STATUS;

    if (!isTradingOnline) {
      return <AmountDisplay amount={undefined} label={label} />;
    } else if (!isCreditEnabled) {
      if (isCashBalanceAboveMaxPurchaseAmount) {
        return (
          <AmountDisplayWithTooltip
            amount={maximumPurchaseAmount.USD}
            label={label}
            tooltipContent={balanceExceedsMaxPurchaseToolTip}
          />
        );
      } else {
        return <AmountDisplay amount={maximumPurchaseAmount.USD} label={label} />;
      }
    } else {
      if (isCreditAndCashAboveMaxPurchase) {
        return (
          <AmountDisplayWithTooltip
            amount={maximumPurchaseAmount.USD}
            label={label}
            tooltipContent={balanceExceedsMaxPurchaseToolTip}
          />
        );
      } else {
        return (
          <AmountDisplayWithTooltip
            amount={maximumPurchaseAmount.USD}
            label={label}
            tooltipContent={
              <AvailableCreditTooltip
                limit={credit.availableUsd}
                cashBalance={cashBalanceUsd}
                maxPurchase={maximumPurchaseAmount.USD}
              />
            }
          />
        );
      }
    }
  };

  return (
    <div className="mt-2">
      <CurrentPriceDisplay price={bitcoinPrice} label="Current price" />
      <div className={styles.amountInstructionsContainer}>
        <CashBalanceDisplay />
        {!isIraOrg && (
          <Button
            className={styles.instructionsButton}
            onClick={openCashDepositSlideout}
            variant="text"
          >
            Wire instructions
          </Button>
        )}
      </div>
      <MaxPurchaseDisplay />
    </div>
  );
};
