import { useMemo } from "react";

import {
  Button,
  Loader,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  WizardStep,
} from "@unchained/component-library";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { useNavigate } from "Components/Link";
import { FullPageWizard } from "Components/Shared/Layouts/FullPageWizard";
import { CloseButton } from "Components/Shared/Layouts/FullPageWizard/components";
import { LoadingContextProvider, useLoadingContext } from "Contexts/LoadingContext";
import { useCurrentUser } from "Redux/selectors/hooks";
import { orgQueryKeys, useGetOrg, useOrgChangeProposals } from "Shared/api";
import { ChangeProposalAPI } from "Shared/api/changeProposalApi";
import { AppModalManager } from "Shared/components/Modals";
import { useEasyToasts } from "Utils/toasts";
import { useAndRemoveQueryParams } from "Utils/uris";

import ChangeProposalStep from "./ChangeProposal/ChangeProposal";

const ConfirmCancelModal = ({ onCancel }) => {
  const close = (val: boolean) => {
    if (val) onCancel();
    AppModalManager.close();
  };
  return (
    <Modal maxWidth="xs" onDismiss={() => close(false)}>
      <ModalHeader>
        <ModalTitle>Cancel quorum approval</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <p>This will end the quorum approval process and no changes will go into effect.</p>
      </ModalContent>
      <ModalFooter>
        <Button color="primary" onClick={() => close(true)}>
          Yes, cancel
        </Button>
        <Button variant="text" onClick={() => close(false)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default function ChangeProposalGroup() {
  const { uuid, groupUuid } = useParams();
  const orgQuery = useGetOrg(uuid);
  const orgChangeProposalsQuery = useOrgChangeProposals(uuid);
  const queryClient = useQueryClient();
  const { showApiSuccessToast, showErrorToast } = useEasyToasts();
  const currentUser = useCurrentUser();
  const { from } = useAndRemoveQueryParams("from");
  const cancelChangeProposal = useMutation(() =>
    ChangeProposalAPI.RevokeChangeProposalGroup(uuid, groupUuid)
  );
  const navigate = useNavigate();
  const loading = useLoadingContext();
  if (orgChangeProposalsQuery.isLoading || orgQuery.isLoading) return <Loader className="m-20" />;
  if (orgChangeProposalsQuery.error || orgQuery.error) {
    showErrorToast(orgChangeProposalsQuery.error || orgQuery.error, {
      description: "Error loading change proposals",
    });
    navigate("/home");
    return null;
  }

  const changeProposalGroup = orgChangeProposalsQuery.data["changeProposalGroups"].find(
    (group: any) => group.uuid === groupUuid
  );

  const showCancelButton =
    changeProposalGroup.state === "pending" &&
    changeProposalGroup.requester.uuid === currentUser.uuid;

  const onCancel = () => {
    loading.set(true);
    cancelChangeProposal.mutate(undefined, {
      onSuccess: async () => {
        showApiSuccessToast({ message: "Successfully canceled change proposal" });
        queryClient.invalidateQueries(orgQueryKeys.changeProposals(uuid));
        const data = await ChangeProposalAPI.GetOrgChangeProposalGroups(uuid);
        queryClient.setQueryData(orgQueryKeys.changeProposals(uuid), data);
        loading.set(false);
      },
      onError: err => showErrorToast(err),
    });
  };

  const exitLink = from ? from : `/orgs/${uuid}/change-proposals`;

  const manifest: (WizardStep & { skip?: boolean })[] = [
    {
      title: "Quorum action",
      AlternateIcon: () => <CloseButton to={exitLink} />,
      Component: () => (
        <ChangeProposalStep
          group={changeProposalGroup}
          org={orgQuery.data}
          currentUser={currentUser}
          exitLink={exitLink}
        />
      ),
    },
  ];

  return (
    <FullPageWizard
      data-testid="change-proposal-group-wizard"
      manifest={manifest}
      isShowUnchainedLogo={!showCancelButton}
      stepperFooter={
        showCancelButton && (
          <Button
            fullWidth={true}
            color="destructive"
            className="w-full !rounded-none"
            onClick={() => AppModalManager.open(() => <ConfirmCancelModal onCancel={onCancel} />)}
          >
            Cancel quorum approval
          </Button>
        )
      }
      footerStyles={showCancelButton ? "w-full" : undefined}
      desktopLayout="top"
    />
  );
}
