import { FC } from "react";

import { Loader } from "@unchained/component-library";
import { pick } from "lodash";

import { useGetOrg } from "Shared/api/hooks/orgs";
import { useWorkspaceData } from "Shared/api/v2/hooks/workspaces";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { OrgItem } from "Specs/v2/components";
import { GetUserWorkspaceData200 } from "Specs/v2/getUserWorkspaceData/200";

import { withQueries } from "./withQuery";

type BaseInjectedProps = GetUserWorkspaceData200 & {
  org?: OrgItem;
  accountId?: string;
  itemId?: string;
  tabName?: string;
  /** Only provided if opts.fetchCompleteOrg is passed AND the page has an accountId param */
  completeOrg?: CompleteOrg;
};

export function withWorkspaceData<DirectProps, InjectedProps = BaseInjectedProps>(
  Component: FC<InjectedProps & DirectProps>,
  options: {
    fetchCompleteOrg?: boolean;
    loader?: React.ReactNode;
  } = {}
): FC<DirectProps> {
  const opts = {
    fetchCompleteOrg: false,
    loader: <Loader className="h-screen w-full" />,
    ...options,
  };
  const queryOpts = pick(opts, "loader");

  // Query getters
  const useOrgItem = ({ params, props }) => {
    const { accountId, tabName, itemId } = params;

    return {
      data: {
        org: (props as GetUserWorkspaceData200)?.orgs?.find(o => o?.id && o.id === accountId),
        accountId,
        itemId,
        tabName,
      },
    };
  };

  const useCompleteOrg = ({ params }) => {
    if (!params.accountId) throw new Error("No accountId provided to fetch complete org");
    return useGetOrg(params.accountId, { enabled: !!params.accountId });
  };

  const queryTriples = [
    [useWorkspaceData, undefined, queryOpts], // Results spread into props
    [useOrgItem, undefined, queryOpts], // Results spread into props
  ] as Parameters<typeof withQueries>[1];

  if (opts.fetchCompleteOrg) {
    queryTriples.push([useCompleteOrg, "completeOrg", queryOpts]);
  }

  return withQueries(Component, queryTriples) as FC<DirectProps>;
}
