import { ComponentType, FC } from "react";

import { Navigate } from "react-router-dom";

import { withWorkspaceData } from "Shared/components/HOCs/withWorkspaceData";

type Props = {
  Component: FC | ComponentType;
  /** If blocking, display this alternative. */
  Alternative?: FC;
  /** If falsy (default) block if user can't view Unified IA. If truthy, block in the opposite case. */
  reverse?: boolean;
};

export const WorkspaceProtect = withWorkspaceData<Props>(
  ({ reverse, user, Component, Alternative = () => <Navigate to="/home" /> }) => {
    if (reverse ? user.canViewUnifiedIA : !user.canViewUnifiedIA) return <Alternative />;

    return <Component />;
  }
);
