import { useQuery, UseQueryOptions } from "react-query";

import { GetUserWorkspaceData200 } from "Specs/v2";

import { WorkspacesAPI } from "..";

export const workspaceQueryKeys = {
  get: ["workspace"],
};

type GetWorkspaceOptions = UseQueryOptions<GetUserWorkspaceData200> & {
  blockOnError?: boolean;
};

/** Requests the workspace for the current user. */
export const useWorkspaceData = (options: GetWorkspaceOptions = {}) =>
  useQuery<GetUserWorkspaceData200>(
    workspaceQueryKeys.get,
    () => WorkspacesAPI.GetUserWorkspaceData(options.blockOnError),
    options
  );
