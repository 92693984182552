import { useToast } from "@unchained/component-library";
import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { refreshLoanShowAction } from "Actions/loanActions/loanShowActions";
import { CreateNonPlaidBankAccountRequest } from "Specs/v1";
import { BankAccount } from "Specs/v1/getAccount/200";

import { BankAccountAPI } from "../";

export const bankAccountKeys = {
  getOrgBankAccountsAdmin: (orgUuid: string) => ["getOrgBankAccountsAdmin", orgUuid],
  bankAccountsWithValidNonPlaid: "linkedAccounts",
  getClientBankAccounts: (orgUuid: string) => ["getClientBankAccounts", orgUuid],
  loanInterestAccount: (loanUuid: string) => ["loanInterestAccount", loanUuid],
  adminBankAccounts: (orgUuid: string) => `bankAccountsAdmin-${orgUuid}`,
};

export const useCreateNonPlaidBankAccount = (orgUuid: string, onSuccess?: () => void) => {
  const { enqueueSimpleToast } = useToast();
  const queryClient = useQueryClient();
  return useMutation(
    async (values: CreateNonPlaidBankAccountRequest) => {
      return await BankAccountAPI.CreateNonPlaidBankAccount(orgUuid, values);
    },
    {
      onSuccess: () => {
        enqueueSimpleToast({
          type: "success",
          title: "Success",
          description: "You have successfully added a bank account.",
          dismissable: true,
        });
        queryClient.invalidateQueries(bankAccountKeys.getClientBankAccounts(orgUuid));
        onSuccess();
      },
      onError: () => {
        enqueueSimpleToast({
          title: "Could not create bank account",
          type: "error",
          description: "If this issue persists, please contact support.",
          dismissable: true,
          persist: true,
        });
      },
    }
  );
};

export const useGetOrgBankAccountsAdminQuery = (orgUuid?: string) => {
  return useQuery(
    bankAccountKeys.getOrgBankAccountsAdmin(orgUuid),
    () => BankAccountAPI.GetOrgBankAccountsAdmin(orgUuid),
    {
      refetchOnWindowFocus: true,
      enabled: !!orgUuid,
    }
  );
};

export const useGetOrgBankAccountsWithValidNonPlaid = (
  orgUuid: string,
  handleOnSuccess?: (data: BankAccount[]) => void,
  handleOnError?: (err: AxiosError) => void
) => {
  return useQuery(
    bankAccountKeys.bankAccountsWithValidNonPlaid,
    () => BankAccountAPI.GetOrgBankAccountsWithValidNonPlaid(orgUuid),
    {
      refetchOnWindowFocus: false,
      enabled: !!orgUuid,
      onSuccess: data => {
        if (handleOnSuccess) {
          handleOnSuccess(data);
        }
      },
      onError: (err: AxiosError) => {
        if (handleOnError) {
          handleOnError(err);
        }
      },
    }
  );
};

export const useGetClientBankAccountsQuery = (orgUuid: string) => {
  // This hook can be used to encapsulate logic related to client bank accounts
  // It can combine multiple queries and mutations related to bank accounts
  // and provide a simplified interface for components to interact with.

  return useQuery(
    bankAccountKeys.getClientBankAccounts(orgUuid),
    () => BankAccountAPI.GetAllOrgBankAccounts(orgUuid),
    {
      // These have to be false or else upon exit of Plaid Link, the page sees
      // it
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetLoanInterestAccount = (loanUuid?: string) => {
  return useQuery(bankAccountKeys.loanInterestAccount(loanUuid), () =>
    BankAccountAPI.GetLoanInterestAccount(loanUuid)
  );
};

export const useSetLoanInterestAccount = (loanUuid: string) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    (values: { accountId: string; autoAch: boolean }) =>
      BankAccountAPI.SetLoanInterestAccount(loanUuid, values.accountId, values.autoAch),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(bankAccountKeys.loanInterestAccount(loanUuid));

        // NOTE: This hook is used in a component that combines react-query and the redux store.
        // To refetch react-query data, we invalidate the queries above.
        // To update the redux store, we dispatch the refreshLoanShowAction,
        // ensuring the loan from the parent component is refreshed and propagated down.
        dispatch(refreshLoanShowAction(loanUuid));
      },
    }
  );
};
