import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from "axios";
import { capitalize, get, merge } from "lodash";
import { ValueOf } from "type-fest";

import { getConfig } from "Utils/config";
import { editCommonMessages } from "Utils/errors";
import { stringifyQueryParams } from "Utils/uris";

import { TrefoilMockExamples } from "./PrismMockSingleton";
import { AxiosParams, AxiosData, AxiosConfig, AxiosResponseType } from "./apiType";
import { API_LOCATION, MOCK_API_LOCATION } from "./constants";

const CSRF_TOKEN = window.CSRF_TOKEN;

axios.defaults.headers.common["X-CSRFToken"] = CSRF_TOKEN;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// We need to specify no cache in our API calls to ensure we get the most up-to-date data &
// avoid conflicts between duplicated get requests that return either html or json.
axios.defaults.headers.common["Cache-Control"] = "no-cache, no-store, must-revalidate";
axios.defaults.headers.common["Pragma"] = "no-cache";
axios.defaults.headers.common["Expires"] = "0";

const REQUEST_STATUS = {
  PENDING: "pending",
  SUCCESS: "success",
  NOT_FOUND: "not_found",
  FORBIDDEN: "forbidden",
  ERROR: "error",
  STALE: "stale",
} as const;
export { REQUEST_STATUS };
export type RequestStatus = ValueOf<typeof REQUEST_STATUS>;

const COSIMO_URL = getConfig("trading_url");

const checkForCommonErrors = error => {
  if (error.response) {
    const { data, statusText } = error.response;
    if (statusText === "UNAUTHORIZED") {
      window.location.href = `/login`;
    }

    if (get(error, "response.data.message")) {
      error.response.data.message = editCommonMessages(error.response.data.message);
    }
    if (data && data.message) {
      const next =
        window.location.pathname === "/logout"
          ? ""
          : `?next=${encodeURIComponent(window.location.pathname)}`;
      switch (data.message) {
        case "Your session has ended. Please log in.": // response if session token has timed out
          window.location.href = `/login${next}`;
          break;

        case "Your credentials do not match. Please log in.":
          window.location.href = `/login${next}`;
          break;

        case "Cannot connect to server.":
          window.location.href = "/login";
          break;

        case "Please try again using https://":
          window.location.href = `/login${next}`;
          break;

        case "You are already logged in.":
          window.location.href = `/`;
          break;

        case "Have you logged in yet?":
          window.location.href = `/login${next}`;
          break;

        default:
          throw error;
      }
    }
    throw error;
  } else {
    if (error.code === "ECONNABORTED") {
      console.error("Server error: We can not process your request.");
      return;
    }

    throw error;
  }
};

class ApiBase {
  basePathPattern: string | RegExp;
  basePathReplace: string;

  constructor(basePathPattern?: string | RegExp, basePathReplace?: string) {
    this.basePathPattern = basePathPattern;
    this.basePathReplace = basePathReplace;
  }

  Get = async <T>(
    resource,
    params: AxiosParams = {},
    config: AxiosConfig = {},
    responseType: AxiosResponseType = "json"
  ) => {
    return await this._requestAndCatch<T>({
      ...config,
      url: resource,
      method: "get",
      params,
      responseType,
    });
  };

  Post = async <T>(resource: string, data?: AxiosData) => {
    return await this._requestAndCatch<T>({
      url: resource,
      data,
      method: "post",
    });
  };

  Delete = async <T>(resource: string, data?: AxiosData) => {
    return await this._requestAndCatch<T>({ url: resource, method: "delete", data });
  };

  Put = async <T>(resource: string, data: AxiosData = {}, headers: AxiosRequestHeaders = {}) => {
    return await this._requestAndCatch<T>({ method: "put", url: resource, data, headers });
  };

  Patch = async <T>(resource: string, data: AxiosData = {}) => {
    return await this._requestAndCatch<T>({
      method: "patch",
      url: resource,
      data,
    });
  };

  /**
   * A patch which conforms to the JSON merge-patch spec:
   * https://datatracker.ietf.org/doc/html/rfc7396#section-3
   **/
  MergePatch = async <T>(resource: string, data: AxiosData = {}) => {
    return await this._requestAndCatch<T>({
      method: "patch",
      url: resource,
      data,
      headers: { "Content-Type": "application/merge-patch+json" },
    });
  };

  _createFormData(data: AxiosData) {
    let i;
    let formData = new FormData();
    const keys = Object.keys(data);
    for (i = 0; i < keys.length; i++) {
      const key = keys[i];
      const file = data[key];
      formData.append(key, file);
    }
    return formData;
  }

  PutFiles = async <T>(resource: string, data: AxiosData = {}) => {
    const formData = this._createFormData(data);
    return await this._requestAndCatch<T>({
      method: "put",
      url: resource,
      data: formData,
    });
  };

  PatchFiles = async <T>(resource: string, data: AxiosData = {}) => {
    const formData = this._createFormData(data);
    return await this._requestAndCatch<T>({
      method: "patch",
      url: resource,
      data: formData,
    });
  };

  Download = async <T extends BlobPart & { type: string }>(resource: string) => {
    // Downloading the document from pleather can take ~15 seconds at the
    // but when you click on the Download button, it gives the user no
    // indication that anything has changed.
    let response = await this._request<T>({
      method: "get",
      url: resource,
      responseType: "blob",
    }).catch(error => {
      throw error;
    });

    const type = response.data.type;
    if (type === "application/text" || type === "application.json") {
      throw response.data;
    }

    const blob = new Blob([response.data], { type: response.data.type });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = url;
    const contentDisposition = response.headers["content-disposition"];
    let fileName = "New document";
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename=(.+)$/);
      if (fileNameMatch && fileNameMatch.length === 2) fileName = fileNameMatch[1];
    }
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  /** Perform an axios request with a "full config", given a partial one. */
  _request = <T>(config: AxiosRequestConfig) =>
    axios.request(this._config(config)) as Promise<AxiosResponse<T>>;

  /** Perform an axios request with a "full config", given a partial one.
   * Catch any errors in a consistent manner */
  _requestAndCatch = <T>(config: AxiosRequestConfig) =>
    this._request<T>(config).catch(error => {
      error.source = "API response";
      error.errorType = `API ${capitalize(config.method)} Request`;
      checkForCommonErrors(error);
    }) as Promise<AxiosResponse<T>>;

  /** Generates a full axios request config given an incoming config */
  _config = (config: AxiosConfig) => {
    let resourcePath = config.url; // It's not a full URL yet
    const method = config.method || "get";

    let basePath = API_LOCATION;
    if (this.basePathPattern && this.basePathReplace) {
      basePath = basePath.replace(this.basePathPattern, this.basePathReplace);
    }

    // Check for a mock in the mock registry, and request from Prism if it exists
    const mockExample = TrefoilMockExamples.get(resourcePath, config.method);
    let url = `${mockExample ? MOCK_API_LOCATION : basePath}${resourcePath}`;

    /**
     * Params for GET requests should be stringified appropriately.
     * By default, use "list" format (key=value1,value2),
     * but if `explodeQueryParams` is passed, stringify params accordingly (key=value&key=value2)
     **/
    if (method === "get" && config.params) {
      config.paramsSerializer = params => stringifyQueryParams(params, config.explodeQueryParams);
    }

    const headers = merge(
      {},
      mockExample ? { Prefer: `example=${mockExample}` } : {},
      config.headers || {}
    );

    const finalConfig = merge(
      // This is a global default, but can be overwritten
      { withCredentials: true },
      config,
      // Final URL is the one constructed using the API_LOCATION, not the simple resource passed in
      { url, headers }
    );

    if (process.env.REACT_APP_LOG_API && process.env.NODE_ENV === "development") {
      console.info([
        `${method.toUpperCase()} ${resourcePath}`,
        ...[finalConfig, mockExample && { mockExample }].filter(Boolean),
      ]);
    }

    return finalConfig;
  };
}

export const API = new ApiBase();
export const API_V2 = new ApiBase(/v1/, "v2");

/**
 * Given the promise returned by an API request (eg SomeApi.Get()),
 * returns a promise which resolves the data of the successful request on success,
 * and otherwise behaves the same.
 *
 * @param {Promise} apiRequest
 * @returns Promise
 */
export const resolveData = <T extends { data: unknown }>(apiRequest: Promise<T>) =>
  new Promise((resolve, reject) =>
    apiRequest.then(({ data }) => resolve(data)).catch(error => reject(error))
  );

export class CosimoAPI {
  static GetAPIVersion = () => {
    return "/api/v1";
  };

  static Get = async (resource, params: AxiosParams = {}, headers = {}) => {
    const baseApi = `https://${COSIMO_URL}${CosimoAPI.GetAPIVersion()}`;

    return (await axios
      .get(`${baseApi}${resource}`, {
        withCredentials: true,
        params: params,
        headers: headers,
      })
      .catch(error => {
        error.source = "CosimoAPI response";
        error.errorType = "CosimoAPI Get Request";
        checkForCommonErrors(error);
      })) as AxiosResponse;
  };

  static OpenWebSocket = resource => {
    const baseApi = `wss://${COSIMO_URL}${CosimoAPI.GetAPIVersion()}`;
    try {
      return new WebSocket(`${baseApi}${resource}`);
    } catch (e) {
      throw e;
    }
  };
}
