import React, { useContext, useState } from "react";

import { Collapse, Divider } from "@mui/material";
import {
  AddressString,
  Button,
  Modal,
  useToast,
  WizardStepperContext,
} from "@unchained/component-library";
import { useSelector } from "react-redux";

import { ConfirmOnDeviceIcon } from "Components/Shared/Elements/Account/ConfirmAddress/ConfirmOnDeviceIcon";
import { SigningKeyList } from "Components/Transactions/Spending/SigningStep/SigningKeyList";
import { SpendVerificationVideo } from "Components/Transactions/Spending/SpendVerificationVideo";
import { getOutputBucketsFromTxOutputs } from "Components/Transactions/Spending/TransactionDetails/getOutputBucketsFromTxOutputs";
import {
  canUserVerify,
  createVerificationScript,
} from "Components/Transactions/Spending/VerificationStep";
import { VerificationRecordModal } from "Components/verifications/VerificationRecordModal";
import { ConfirmOnDeviceWizard } from "Components/wallets/ConfirmOnDeviceWizard";
import { useSellBitcoinStore } from "Contexts/SellBitcoin/sellBitcoinContext";
import {
  getCurrentOrg,
  getCurrentOrgId,
  spendingOperationSelector,
  spendingSigRequestsSelector,
  spendingTransactionSelector,
} from "Redux/selectors/spendingSelectors";
import { useUpdateSellBitcoinConfirmSignatures } from "Shared/api/hooks/trading";
import { Verification } from "Specs/v1/getAccount/200";
import { formatCurrency } from "Utils/strings";

import {
  calculateAmountUSDToBeSentToClient,
  calculateFeeAmountInUSD,
  calculateSaleAmountInUSD,
  sellBitcoinNetworkFailureToastDescription,
} from "../../helpers";
import { CancelModalWrapper } from "../components/CancelModalWrapper";
import { LoadableButton } from "../components/LoadableButton";
import { SaveProgressAndCloseButton } from "../components/SaveProgressAndCloseButton";
import { SellHeader } from "../components/SellHeader";
import { SummaryTable } from "./ReviewTransactionStep";
import styles from "./SignTransactionStep.module.scss";

type SignTransactionStepProps = {
  onContinue?: () => void;
  onClose: () => void;
};
export const SignTransactionStep = React.memo(
  ({ onContinue, onClose }: SignTransactionStepProps) => {
    const {
      saleAmountBTC,
      selectedSource,
      bankName,
      bankLastFourDigits,
      transactionUuid,
      transactionFeeAmountUSD,
      transactionFeeAmountBTC,
      transactionFeeSatsVByte,
      unchainedBTCReceiveAddress,
      customerBTCChangeAddress,
      feeRates,
      currentSaleUuid,
      bitcoinPrice,
      receivingAccountType,
      cashBalanceUsd,
    } = useSellBitcoinStore();

    const saleAmountUSD = calculateSaleAmountInUSD(bitcoinPrice, saleAmountBTC);
    const feeAmountUSD = calculateFeeAmountInUSD(saleAmountUSD, feeRates);
    const amountUSDToBeSentToClient = calculateAmountUSDToBeSentToClient(
      saleAmountUSD,
      feeAmountUSD
    );

    const { goToNext } = useContext(WizardStepperContext);
    const orgUuid: string = useSelector(getCurrentOrgId);
    const currentOrg = useSelector(getCurrentOrg);
    const isIraOrg = currentOrg.account_type === "ira";

    const { enqueueSimpleToast } = useToast();
    const [isShowVerificationModal, setIsShowVerificationModal] = useState(false);
    const [isShowViewVerificationModal, setIsShowViewVerificationModal] = useState(false);

    const [isShowConfirmOnDeviceWizard, setIsShowConfirmOnDeviceWizard] = useState(false);

    const [isVideoRecordingProcessing, setIsVideoRecordingProcessing] = useState(false);

    const { sigRequests } = useSelector(spendingSigRequestsSelector);
    const { transactionOutputs } = useSelector(spendingTransactionSelector);
    const {
      verification,
      verificationRequired: userImposedVerification,
      operation,
      unit,
      accountType,
      accountUUID,
      spending,
    } = useSelector(spendingOperationSelector);

    const { changeOutputs } = getOutputBucketsFromTxOutputs(
      transactionOutputs,
      accountType,
      accountUUID
    );

    const changeAmount = changeOutputs.length > 0 ? changeOutputs[0].amount : 0;

    const outputTotal = `${formatCurrency(changeAmount, 8)} BTC`;

    const handleRecordVideo = () => {
      setIsShowVerificationModal(true);
    };
    const handleShowRecordedVideo = () => {
      setIsShowViewVerificationModal(true);
    };

    const getCompletedSignaturesCount = () => {
      const completedSignatures = sigRequests.filter(sigRequest => sigRequest.state === "complete");
      return completedSignatures.length;
    };

    const isUnchainedAskedToSign = () => {
      const unchainedKey = sigRequests.find(
        sigRequest => sigRequest.account_key.role === "unchained" && sigRequest.requested
      );
      return !!unchainedKey;
    };

    const isVideoRecordingComplete = !["failed", "pending_recording"].includes(verification.state);
    const isSigningThresholdMet = getCompletedSignaturesCount() >= 2;
    const isVerificationRequired = userImposedVerification || isUnchainedAskedToSign();

    const isContinueEnabled = isVerificationRequired
      ? isSigningThresholdMet && isVideoRecordingComplete
      : isSigningThresholdMet;

    const stepSubTitle = isUnchainedAskedToSign()
      ? "Select your key below to sign. You will be notified when Unchained has signed and your transaction is ready to broadcast."
      : "Sign with both keys below; select either one to begin.";

    const afterVerificationUploaded = () => {
      setIsShowVerificationModal(false);
      setIsVideoRecordingProcessing(true);
    };

    const VerificationModal = () => {
      return (
        <Modal>
          <VerificationRecordModal
            verification={verification as Verification}
            script={createVerificationScript(operation, unit, accountType, accountUUID, spending)}
            afterUploaded={afterVerificationUploaded}
            isRequired={isVerificationRequired}
            onClose={() => setIsShowVerificationModal(false)}
          />
        </Modal>
      );
    };

    const showViewButton = () => {
      const videoVerification = verification as Verification;

      return (
        isVideoRecordingProcessing ||
        (videoVerification &&
          videoVerification.state &&
          videoVerification.state !== "pending_recording" &&
          videoVerification?.allowed_actions?.includes("view_verification"))
      );
    };

    const ViewVerificationVideoModal = () => {
      const title = "Sell bitcoin verification";
      const spendingDetails = {
        spendType: "vault_sale",
        productUuid: selectedSource?.id,
        operationUuid: transactionUuid,
        productType: "vault",
      };
      return (
        <Modal>
          <SpendVerificationVideo
            title={title}
            spendDetails={spendingDetails}
            onClose={() => setIsShowViewVerificationModal(false)}
          />
        </Modal>
      );
    };

    const [isPopperOpen, setIsPopperOpen] = useState(false);

    const recordPopperState = React.useCallback(isOpen => {
      setIsPopperOpen(isOpen);
    }, []);

    const onContinueSuccess = () => {
      onContinue();
      goToNext();
    };

    const handleOnContinueError = () => {
      enqueueSimpleToast(sellBitcoinNetworkFailureToastDescription);
    };

    const {
      mutate: updateSellBitcoinConfirmSignatures,
      isLoading: isLoadingUpdateSellBitcoinConfirmSignatures,
    } = useUpdateSellBitcoinConfirmSignatures({
      orgUuid,
      currentSaleUuid,
      handleOnSuccess: onContinueSuccess,
      handleOnError: handleOnContinueError,
    });

    const handleNext = () => {
      updateSellBitcoinConfirmSignatures();
    };

    const isShowBTCAddresses = isPopperOpen && !isSigningThresholdMet;

    const devicesText = isUnchainedAskedToSign() ? "device" : "devices";

    return (
      <CancelModalWrapper closeStepper={onClose}>
        <div className="mb-4 mt-8 max-h-screen w-[59vw] max-w-[700px]">
          {isShowVerificationModal ? <VerificationModal /> : null}
          {isShowViewVerificationModal ? <ViewVerificationVideoModal /> : null}
          <SellHeader className="mb-5" title="Sign transaction" subTitle={stepSubTitle} />
          {isShowBTCAddresses ? (
            <div className={`${styles.collapsibleContainer} ${styles.open}`}></div>
          ) : null}
          <Collapse
            in={isShowBTCAddresses}
            timeout={400}
            easing={{
              enter: "ease",
              exit: "ease",
            }}
          >
            <div
              className="mb-5 w-full rounded-lg bg-yellow-100 px-4 py-3"
              data-testid="verifyOnDevicesContainer"
            >
              <div className="mb-2 mt-1  flex content-center justify-between">
                <p className="font-semi text-gray-800">Verify on your {devicesText}</p>
                <Button
                  to="https://help.unchained.com/how-to-verify-receiving-address-on-hardware-wallet"
                  className={`!text-xs !font-med !text-primary-600 hover:!text-gray-800`}
                  variant="text"
                >
                  Learn more
                </Button>
              </div>
              <Divider className="bg-yellow-300" />
              <div className="mt-2 flex flex-col justify-between ">
                <div className="mb-2 flex flex-col justify-between [@media(min-width:1130px)]:mb-0 [@media(min-width:1130px)]:flex-row ">
                  <p className="mr-auto">Destination address: </p>
                  <AddressString
                    className="mb-1  mr-auto text-left [@media(min-width:1130px)]:text-left"
                    bolded
                    address={unchainedBTCReceiveAddress}
                  />

                  <p className="min-width[20%] text-left [@media(min-width:1130px)]:text-right">
                    {saleAmountBTC} BTC
                  </p>
                </div>
                {changeOutputs.length > 0 ? (
                  <div className=" flex  flex-col justify-between [@media(min-width:1130px)]:flex-row  [@media(min-width:1130px)]:items-center">
                    <p className="mr-auto">Change address: </p>
                    <div className="relative  flex  items-center [@media(min-width:1130px)]:left-[30px] [@media(min-width:1130px)]:mr-auto">
                      <AddressString
                        bolded
                        address={customerBTCChangeAddress}
                        className=" text-left   [@media(min-width:1130px)]:text-left"
                      />
                      <ConfirmOnDeviceIcon
                        address={customerBTCChangeAddress}
                        onClick={() => setIsShowConfirmOnDeviceWizard(true)}
                        classNames={"flex-0 relative top-[2px]"}
                      />
                    </div>
                    <p className="min-width[20%] [@media(min-width:1130px)]:text-right">
                      {outputTotal}
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          </Collapse>

          <div className="h-80 md:h-44">
            <SigningKeyList
              autoRequestUnchainedKeys={true}
              isUseKeyCards={true}
              showTransactionSummary={false}
              isPopperOverFlowParent={true}
              isPopperHideable={true}
              keyHolderStyles="mb-6"
              hideUnrequestedSignatures={true}
              onPopperChange={recordPopperState}
              keySize="md"
            />
          </div>
          <div className="mb-8 mr-8 w-full">
            <SummaryTable
              isTruncatable={true}
              saleAmount={saleAmountBTC}
              vaultName={selectedSource?.name}
              btcRecieveAddress={customerBTCChangeAddress}
              feeAmountInUsd={feeAmountUSD}
              transactionFeeAmountBTC={transactionFeeAmountBTC}
              vaultId={selectedSource?.id}
              amountUSDToBeSentToClient={amountUSDToBeSentToClient}
              btcRecieveAddressUnchained={unchainedBTCReceiveAddress}
              bankAccountName={bankName}
              bankAccountLastFourDigits={bankLastFourDigits}
              transactionFeeSatsVByte={transactionFeeSatsVByte}
              transactionFeeAmountUSD={transactionFeeAmountUSD}
              feeRates={feeRates}
              receivingAccountType={receivingAccountType}
              cashBalanceUsd={cashBalanceUsd}
              isIraOrg={isIraOrg}
            />
          </div>
          {showViewButton() && isVerificationRequired ? (
            <Button
              fullWidth={true}
              color="tertiary"
              className="mb-3 mt-3"
              onClick={handleShowRecordedVideo}
            >
              View video verification
            </Button>
          ) : null}
          {canUserVerify(verification) && !showViewButton() && isVerificationRequired ? (
            <Button
              fullWidth={true}
              color="secondary"
              className="mb-3 mt-3"
              onClick={handleRecordVideo}
            >
              Record a video verification
            </Button>
          ) : null}
          <LoadableButton
            fullWidth={true}
            disabled={!isContinueEnabled || isLoadingUpdateSellBitcoinConfirmSignatures}
            color="primary"
            onClick={handleNext}
            isLoading={isLoadingUpdateSellBitcoinConfirmSignatures}
          >
            Continue
          </LoadableButton>
          <SaveProgressAndCloseButton onClose={onClose} />
        </div>
        {isShowConfirmOnDeviceWizard ? (
          <ConfirmOnDeviceWizard
            address={customerBTCChangeAddress}
            onDismiss={() => setIsShowConfirmOnDeviceWizard(false)}
            onDone={() => setIsShowConfirmOnDeviceWizard(false)}
          />
        ) : null}
      </CancelModalWrapper>
    );
  }
);
