import { useState } from "react";

import { Banner, Button, Loader } from "@unchained/component-library";
import cn from "classnames";
import { useSelector } from "react-redux";

import { Link, useLinksBlocked } from "Components/Link";
import { currentOrgSelector } from "Redux/selectors";
import { useGetOrg, useOrgChangeProposals } from "Shared/api";
import { AppModalManager } from "Shared/components/Modals";
import { CompleteOrg } from "Specs/v1/getAccount/200";

import { InviteMembers } from "./Members/OrgInviteMembersWithRoles";
import { OrgManageMembers } from "./Members/OrgManageMembers";
import { OrgQuorumApprovalSettings } from "./OrgQuorumApprovalSettings";
import { SubmitChangesModal } from "./SubmitChangesModal";
import { rolesForUserUuid } from "./utils";

export default function OrgMembersAndQuorumSettings() {
  const { uuid } = useSelector(currentOrgSelector);
  const orgQuery = useGetOrg(uuid);
  const orgChangeProposalsQuery = useOrgChangeProposals(uuid);
  const [userRoleChanges, setUserRoleChanges] = useState({});
  const [userTradingChanges, setUserTradingChanges] = useState({});
  const [usersToRemove, setUsersToRemove] = useState<string[]>([]);
  const [quorumMinApprovalsChange, setQuorumMinApprovalsChange] = useState(undefined);
  const [quorumApplyToAllTxsChange, setQuorumApplyToAllTxsChange] = useState(undefined);
  const [isEditing, setIsEditing] = useState(false);
  const { setLinksBlocked } = useLinksBlocked();

  if (orgQuery.isLoading || orgChangeProposalsQuery.isLoading) return <Loader className="m-auto" />;

  const org = orgQuery.data as CompleteOrg;

  const { accepted_members, child_roles, allowed_actions, account_type } = org;
  const canChangeRole = allowed_actions.includes("change_member_role");
  const canInviteMember = allowed_actions.includes("add_member");

  const pendingChange = orgChangeProposalsQuery.data?.changeProposalGroups.find(
    group => group.state === "pending"
  );

  const setEditing = (val: boolean) => {
    setLinksBlocked(val);
    setIsEditing(val);
  };

  const markUserForRemoval = email => {
    setUsersToRemove([...usersToRemove, email]);
  };

  const resetChanges = () => {
    setUserRoleChanges({});
    setUserTradingChanges({});
    setUsersToRemove([]);
    setQuorumMinApprovalsChange(undefined);
    setQuorumApplyToAllTxsChange(undefined);
    setEditing(false);
  };

  const doneEditing = () => {
    const initialUserRoles = accepted_members.map(member => {
      return {
        uuid: member.user.uuid,
        roles: new Set(
          rolesForUserUuid(member.user.uuid, child_roles).map(role => role.managed) as string[]
        ),
      };
    });

    const roleChanges = accepted_members
      .map(member => {
        const userId = member.user.uuid;
        const initalRoles = initialUserRoles.find(user => user.uuid === userId).roles;
        const newRoles = userRoleChanges[userId] as string[];

        // user has role change if new roles differ from intial roles
        const userHasRoleChange =
          newRoles !== undefined &&
          !(newRoles.length === initalRoles.size && newRoles.every(role => initalRoles.has(role)));

        let targetRoles = [...(userHasRoleChange ? newRoles : initalRoles)];

        let tradingChange = false;
        // if changing to admin, targetRoles will already contain trading & trading_manager roles
        const userIsAdmin = targetRoles.some(role => role === "admins");
        if (!userIsAdmin && userTradingChanges[userId] !== undefined) {
          if (userTradingChanges[userId]) {
            targetRoles.push("trader_role");
          } else {
            targetRoles = targetRoles.filter(role => role !== "trader_role");
          }
          tradingChange = true;
        }

        return userHasRoleChange || tradingChange
          ? { uuid: userId, managedRoles: targetRoles }
          : null;
      })
      .filter(Boolean);

    const outputChangeProposals: any[] = roleChanges.map(roleChange => ({
      type: "OrgUserRoleChangeProposal",
      userUuid: roleChange.uuid,
      roleUuids: roleChange.managedRoles.map(
        role => child_roles.find(r => r.managed === role).uuid
      ),
      currentRoles: rolesForUserUuid(roleChange.uuid, child_roles),
    }));

    if (usersToRemove.length) {
      outputChangeProposals.push(
        ...usersToRemove.map(email => ({
          type: "OrgMemberRemoveChangeProposal",
          email,
        }))
      );
    }

    if (quorumMinApprovalsChange && quorumMinApprovalsChange !== org.quorum_config?.min_approvals) {
      outputChangeProposals.push({
        type: "OrgQuorumMinApprovalsChangeProposal",
        minApprovals: quorumMinApprovalsChange,
        current: org.quorum_config?.min_approvals,
      });
    }

    if (
      quorumApplyToAllTxsChange !== undefined &&
      quorumApplyToAllTxsChange !== org.quorum_config?.apply_to_all_txs
    ) {
      outputChangeProposals.push({
        type: "OrgQuorumApplyToAllTxsChangeProposal",
        enabled: quorumApplyToAllTxsChange,
        current: org.quorum_config?.apply_to_all_txs,
      });
    }

    if (!outputChangeProposals.length) {
      resetChanges();
      return;
    }

    AppModalManager.open(() => (
      <SubmitChangesModal
        changeProposals={outputChangeProposals}
        onClose={submitted => {
          if (submitted) {
            resetChanges();
          }
        }}
        org={org}
      />
    ));
  };

  return (
    <>
      {pendingChange ? (
        <Banner
          type="pending"
          className="mb-4"
          data-testid="pending-change-proposal"
          title="Pending change proposal"
          actions={[
            {
              text: "View details",
              to: `/orgs/${uuid}/change-proposals/${pendingChange.uuid}?from=${location.pathname}`,
            },
          ]}
        >
          <div>
            A pending change proposal exists for {org.name} and must be approved, rejected, or
            revoked before you can propose new changes.
          </div>
        </Banner>
      ) : null}
      <div
        className={cn(
          "relative mb-4 rounded-lg border bg-white p-4 md:p-8",
          isEditing ? "border-2 border-primary-600" : "border-gray-200"
        )}
      >
        <div
          className={cn(
            "absolute left-[-2px] top-[-2px] rounded-br rounded-tl-lg bg-primary-600 px-3 py-1 text-xs tracking-widest text-white",
            isEditing ? "inline" : "hidden"
          )}
        >
          EDITING
        </div>
        <div className="flex flex-col items-start justify-start gap-6">
          <div className="flex w-full justify-end gap-4">
            <InviteMembers disabled={isEditing || !!pendingChange || !canInviteMember} org={org} />
            <Button
              disabled={!canChangeRole || !!pendingChange}
              color={isEditing ? "primary" : "secondary"}
              onClick={isEditing ? doneEditing : () => setEditing(true)}
              data-testid="edit-org-members-and-settings"
            >
              {isEditing ? "Done editing" : "Edit users and settings"}
            </Button>
            <Button className={cn(!isEditing && "hidden")} color="tertiary" onClick={resetChanges}>
              Cancel
            </Button>
          </div>
          {["trust", "business"].includes(account_type) ? (
            <OrgManageMembers
              isEditing={isEditing}
              userRoleChanges={userRoleChanges}
              userTradingChanges={userTradingChanges}
              usersToRemove={usersToRemove}
              setUserRoleChanges={setUserRoleChanges}
              setUserTradingChanges={setUserTradingChanges}
              markUserForRemoval={markUserForRemoval}
            />
          ) : null}

          <h1 className="mt-4">Quorum approval settings</h1>
          <OrgQuorumApprovalSettings
            org={org}
            isEditing={isEditing}
            minApprovals={
              quorumMinApprovalsChange !== undefined
                ? quorumMinApprovalsChange
                : org.quorum_config?.min_approvals ?? 1
            }
            applyToAllTxs={
              quorumApplyToAllTxsChange !== undefined
                ? quorumApplyToAllTxsChange
                : org.quorum_config?.apply_to_all_txs ?? false
            }
            setQuorumMinApprovalsChange={setQuorumMinApprovalsChange}
            setQuorumApplyToAllTxsChange={setQuorumApplyToAllTxsChange}
          />

          <Link to={`/orgs/${uuid}/change-proposals`}>View change proposals</Link>
        </div>
      </div>
    </>
  );
}
