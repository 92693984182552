import { AddressString, Button, Icons, Tooltip } from "@unchained/component-library";
import QRCode from "qrcode.react";

import { Link } from "Components/Link";
import { handleConfirmOnDeviceClick } from "Components/Shared/wizard/confirmOnDevice";
import { VaultMinimumDepositBanner } from "Components/vaults/VaultMinimumDepositBanner";
import { useVerifyAddressViaEmail } from "Utils/hooks/useVerifyAddressViaEmail";
import { bitcoinQRCodeURL } from "Utils/wallet";

import { SubsectionHeader } from "./SubsectionHeader";

const TooltipContent = () => (
  <>
    We recommend confirming the address being shown on screen before sending bitcoin.{" "}
    <Link to="https://help.unchained.com/how-to-verify-receiving-addresses" target="_blank">
      Learn more
    </Link>
  </>
);

export const DepositAddress = ({
  vaultUuid,
  address,
  suggestedMinimumDepositBtc,
  displayConfirmOnDevice,
}: {
  vaultUuid: string;
  address: string;
  suggestedMinimumDepositBtc: string;
  displayConfirmOnDevice: boolean;
}) => {
  const { verifyAddress, isVerifying } = useVerifyAddressViaEmail(vaultUuid, address, "vault");

  const qrText = bitcoinQRCodeURL({
    address,
  });
  return (
    <div className="flex flex-col gap-4">
      <SubsectionHeader>Deposit address</SubsectionHeader>
      <VaultMinimumDepositBanner minimum={suggestedMinimumDepositBtc} />
      <div className="text-sm font-reg">
        You will receive a new address for each deposit.{" "}
        <Link
          to="https://help.unchained.com/how-do-i-deposit-bitcoin-to-my-vault-or-loan"
          target="_blank"
        >
          How do I deposit bitcoin?
        </Link>
      </div>
      <div className="flex flex-row justify-center">
        <QRCode size={148} value={qrText} level="L" />
      </div>
      <AddressString
        className="flex flex-row justify-center !text-gray-600"
        copyable
        copyOnClickProps={{ chipPosition: "inside-bottom-right" }}
        segmented
      >
        {address}
      </AddressString>
      <div className="flex flex-col gap-1.5">
        <div className="flex flex-row items-center gap-1">
          <div className="text-sm font-semi text-gray-800">Confirm address:</div>
          <Tooltip placement="top" content={<TooltipContent />}>
            <div>
              <Icons.HelpCircle className="h-4 w-4 text-gray-400" />
            </div>
          </Tooltip>
        </div>
        <div className="flex flex-row items-center gap-2">
          {displayConfirmOnDevice && (
            <>
              <Button variant="text" onClick={() => handleConfirmOnDeviceClick(address)}>
                On device
              </Button>
              <div className="h-4 border border-gray-400" />
            </>
          )}
          <Button variant="text" disabled={isVerifying} onClick={verifyAddress}>
            Via email
          </Button>
        </div>
      </div>
    </div>
  );
};
